import { createColumnHelper } from '@tanstack/react-table';
import Loader from 'components/v2/Loader';
import React, { useImperativeHandle } from 'react';
import useContextBar from '../../../components/v2/ContextBar/useContextBar';
import { DataContextProvider } from '../../../components/v2/Data/DataContext';
import DataTable from '../../../components/v2/Data/DataTable';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';
import SortableTableHeader from '../../../components/v2/Table/SortableTableHeader';
import { useTableContext } from '../../../components/v2/Table/TableContextProvider';
import TablePagination from '../../../components/v2/TablePagination/TablePagination';
import {
  usePaginationQueryParams,
  useSortableQueryParams,
} from '../../../utils/use-data-params';
import AttributesManager from '../../reports/components/AttributesManager';
import { useReportContext } from '../../reports/context/ReportContext';
import useReportResults from '../../reports/hooks/use-report-results';
import { AttributeValue } from './AttributeValue';
import DeviceTypeDetails from './DeviceTypeDetails';
import ItemActions from './ItemActions';
import Button from 'components/v2/Button/Button';
import Icon from 'components/v2/Icon/Icon';
import FixedLoader from 'components/FixedLoader/FixedLoader';
import { SearchContextProvider } from 'components/v2/SearchField/SearchContext';

const columnHelper = createColumnHelper<any>();

const ReportDataTable = <Result extends object>({
  data,
  pagination,
  actions,
  onDeviceTypeClick,
  onTitleClick,
}: {
  data: Result[];
  pagination: React.ReactNode;
  actions?: React.ReactNode;
  onDeviceTypeClick?(id: number, label: string): void;
  onTitleClick?(item: any): void;
}) => {
  const { id: reportId } = useReportContext();
  const {
    keyPrefix,
    attributes,
    availableAttributes,
    setAttributes,
    getAttributeLabel,
    itemDetailsAction,
  } = useTableContext();
  const contextBar = useContextBar();

  const defaultOnDeviceTypeClick = (id: number, label: string) => {
    contextBar.open({
      component: () => <DeviceTypeDetails id={id} />,
      icon: null,
      title: label,
    });
  };
  const onDeviceTypeClickAction = onDeviceTypeClick || defaultOnDeviceTypeClick;

  const Manager = React.useMemo(
    () => () => (
      <AttributesManager
        keyPrefix={keyPrefix}
        reportId={reportId}
        onChange={setAttributes}
        attributes={availableAttributes}
        enabledAttributes={attributes}
        renderButton={(props) => (
          <Button
            {...props}
            variant="text-primary"
            size={'large'}
            startIcon={<Icon name="fa-regular fa-edit" />}
          />
        )}
      />
    ),
    [attributes],
  );

  const columns = React.useMemo(() => {
    const items = attributes.map((name) =>
      columnHelper.accessor(name, {
        header: (props) => (
          <SortableTableHeader name={name} label={getAttributeLabel(name)} />
        ),
        cell: (info) => (
          <AttributeValue
            item={info.row.original}
            attribute={name}
            onDeviceTypeClick={onDeviceTypeClickAction}
            onTitleClick={onTitleClick}
          />
        ),
      }),
    );
    items.push(
      columnHelper.accessor('actions', {
        header: Manager,
        cell: ({ row }) => <ItemActions item={row.original} />,
      }),
    );

    return items;
  }, [attributes]);

  return (
    <DataTable
      data={data}
      columns={columns}
      actions={actions}
      pagination={pagination}
      slotProps={{
        tableRow: (item) => ({
          onClick() {
            itemDetailsAction?.(item);
          },
        }),
      }}
    />
  );
};

const ReportTablePagination = ({ total }: { total: number }) => {
  const { attributes } = useTableContext();
  const pagination = usePaginationQueryParams();
  const paginationColSpan = attributes.length + 1; // enabled attributes + column for actions

  return (
    <TablePagination
      count={total}
      page={pagination.page}
      perPage={pagination.perPage}
      setPerPage={pagination.setPerPage}
      setPage={pagination.setPage}
      colSpan={paginationColSpan}
    />
  );
};

type Props = {
  queryKeyPrefix?: string;
  components?: {
    empty?: React.ComponentType<{ params: any }>;
  };
  slotProps?: {
    table?: Pick<
      React.ComponentProps<typeof ReportDataTable>,
      'onDeviceTypeClick' | 'onTitleClick'
    >;
  };
  filtersMode?: 'committed' | 'values';
  queryRef?: any;
};

const Results = (props: Props) => {
  const { id, enabledAttributes, attributes } = useReportContext();
  const { committedValues: params, ...filters } = useFiltersContext();
  const sortable = useSortableQueryParams();
  const pagination = usePaginationQueryParams();
  const {
    queryKeyPrefix,
    components: { empty: Empty = () => null } = {},
    filtersMode = 'committed',
    queryRef,
    slotProps: { table: tableProps = {} } = {},
  } = props;
  const activeParams = filtersMode === 'committed' ? params : filters.values;

  const {
    data = {
      data: [],
      total: 0,
    },
    isLoading,
    isFetching,
    refetch,
  } = useReportResults({
    id,
    params: activeParams,
    sortable,
    pagination,
    queryKeyPrefix,
  });

  useImperativeHandle(queryRef, () => ({
    refetch,
  }));

  return (
    <DataContextProvider refresh={refetch}>
      <SearchContextProvider query={String(activeParams.search || '')}>
        {isFetching && <FixedLoader />}
        {isLoading ? (
          <Loader />
        ) : data?.data.length > 0 ? (
          <ReportDataTable
            {...tableProps}
            data={data.data}
            pagination={<ReportTablePagination total={data.total} />}
          />
        ) : (
          <Empty params={activeParams} />
        )}
      </SearchContextProvider>
    </DataContextProvider>
  );
};

export default Results;

import React from 'react';
import ActionSheet, { Action } from 'components/v2/ActionSheet/ActionSheet';
import { useNavigate } from 'utils/router';
import { applyFilters, Filters } from 'core';
import { useUserIsAdmin } from 'modules/auth/hooks';
import { useTranslation } from 'utils/translation';
import { useDataContext } from '../../../components/v2/Data/DataContext';
import useDirectoryIntelligenceFieldsForm from '../../intelligent-layer/hooks/useDirectoryIntelligenceFieldsForm';
import { Box } from '@mui/material';
import Icon from 'components/v2/Icon/Icon';
import { useRepairRequest } from 'plugins/device/src/components/RepairRequestForm';
import { useMaintenanceRequest } from 'plugins/device/src/components/MaintenanceRequestForm';
import { route } from 'router';
import { useReportContext } from '../../reports/context/ReportContext';
import { Dir } from 'modules/dirs';

type Props = {
  item: Dir;
  refetch?(): void;
  extraActions?: Action[];
};

const ItemActions: React.FC<Props> = ({ item, refetch, extraActions = [] }) => {
  const { id, directoryType } = item;
  const { t } = useTranslation();
  const report = useReportContext();
  const navigate = useNavigate();
  const isAdmin = useUserIsAdmin();
  const openRepairRequest = useRepairRequest(id);
  const openMaintenanceRequest = useMaintenanceRequest(id);
  const dataContext = useDataContext();
  const openFieldsForm = useDirectoryIntelligenceFieldsForm(id, {
    enableTitle: directoryType === 'object-type',
    onSuccess() {
      dataContext.refresh();
      refetch?.();
    },
  });

  const onOpenDetails = () => {
    item.directoryType === 'unique-object'
      ? navigate(route('report.item.details', { id: report.id, item: id }))
      : navigate(route('assets.types.details', { id }));
  };

  const onOpenDocuments = () => {
    onOpenDetails();
  };

  const adminActions = [
    {
      icon: <Icon name="fa-regular fa-pencil" />,
      label: t('Edit'),
      action: openFieldsForm,
    },
    {
      icon: <Icon name="fa-solid fa-folder" />,
      label: t('Documents'),
      action: onOpenDocuments,
    },
  ];

  const actions = [
    {
      icon: <Icon name="fa-solid fa-search" />,
      label: t('Details'),
      action: onOpenDetails,
    },
    ...extraActions,
    ...(isAdmin ? adminActions : []),
    {
      icon: <Icon name="fa-solid fa-screwdriver-wrench" />,
      label: t('maintenance_request'),
      action: openMaintenanceRequest,
      visible: directoryType === 'unique-object',
    },
    {
      icon: <Icon name="fa-solid fa-toolbox" />,
      label: t('service_request'),
      action: openRepairRequest,
      visible: directoryType === 'unique-object',
    },
  ];

  const finalActions = applyFilters(Filters.reports.extraItemActions, actions, {
    isAdmin,
    id,
  });

  return (
    <Box sx={{ textAlign: 'right' }}>
      <ActionSheet actions={finalActions} />
    </Box>
  );
};

export default ItemActions;

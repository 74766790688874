import React from 'react';
import { useTranslation } from 'utils/translation';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import {
  Grid,
  Switch,
  Theme,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { Dir } from 'modules/dirs';
import { useDirsActions } from 'modules/dirs/hooks';
import { PermissionsManagement } from './PermissionsManagment';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  dir: Dir;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
  switcher: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: theme.spacing(6),
    margin: theme.spacing(1, 0),
  },
}));

const PublicDirInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item className={classes.info}>
      <SpeakerNotesIcon className={classes.icon} />
      <Typography>{t('This folder is accessible to every user')}</Typography>
    </Grid>
  );
};

const DirPermissionsForm: React.FC<Props> = ({ dir }) => {
  const { pathUpdateDir } = useDirsActions();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDirSettingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    pathUpdateDir({
      id: dir.id,
      isPublic: !checked,
    });
  };

  return (
    <div className={classes.root}>
      {dir.isPublic && <PublicDirInfo />}
      <div className={classes.switcher}>
        <FormControlLabel
          label={t('Install permissions per user')}
          control={
            <Switch
              checked={!dir.isPublic}
              onChange={handleDirSettingChange}
              value={!dir.isPublic}
              color="primary"
            />
          }
        />
      </div>
      {!dir.isPublic && <PermissionsManagement dir={dir} />}
    </div>
  );
};

export { DirPermissionsForm };

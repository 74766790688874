import React from 'react';
import { Dir } from '..';
import ActionSheet from 'components/v2/ActionSheet/ActionSheet';
import Icon from 'components/v2/Icon/Icon';
import usePermissions from '../../auth/permissions';
import { useBasketActions, useIsDirInBasket } from '../../basket/hooks';
import { useDirsActions } from '../hooks';
import { useTranslation } from 'utils/translation';
import useConfirmation from '../../../components/v2/Confirmation/use-confirmation';
import { useDataContext } from '../../../components/v2/Data/DataContext';
import useDirectoryMove from '../hooks/useDirectoryMove';
import useRenameDirectory from '../hooks/useRenameDirectory';
import { refetchActiveQueries } from '../../../utils/QueryClientProvider';
import wait from '../../../utils/wait';

export const useDirectoryActions = (dir: Pick<Dir, 'id'>) => {
  const { t } = useTranslation();
  const { can } = usePermissions();
  const confirm = useConfirmation();
  const { downloadDir, deleteDir } = useDirsActions();
  const dataContext = useDataContext();
  const basketActions = useBasketActions();
  const isInBasket = useIsDirInBasket(dir.id);

  const onMove = useDirectoryMove(dir);

  const onEdit = useRenameDirectory(dir, {
    onSuccess() {
      dataContext.refresh();
      void refetchActiveQueries();
    },
  });

  const onAddToBasket = () => {
    basketActions.addDirectory({ dir });
  };

  const onRemoveFromBasket = () => {
    basketActions.removeDirectory({ dir });
  };

  const onDownload = () => {
    downloadDir(dir);
  };

  const onDelete = async () => {
    const confirmed = await confirm();

    if (confirmed) {
      deleteDir(dir);
      await wait(200);
      dataContext.refresh();
      void refetchActiveQueries();
    }
  };

  return [
    {
      label: t('Download'),
      icon: <Icon name="fa-regular fa-download" />,
      action: onDownload,
    },
    {
      label: t('Add To Collection'),
      icon: <Icon name="fa-regular fa-square-plus" />,
      action: onAddToBasket,
      visible: !isInBasket,
    },
    {
      label: t('Remove From Collection'),
      icon: <Icon name="fa-regular fa-times" />,
      action: onRemoveFromBasket,
      visible: isInBasket,
    },
    {
      label: t('Move'),
      icon: <Icon name="fa-regular fa-arrow-up-right" />,
      action: onMove,
      visible: can('update directory'),
    },
    {
      label: t('Remove'),
      icon: <Icon name="fa-regular fa-trash" />,
      action: onDelete,
      visible: can('delete directory'),
    },
    {
      label: t('Edit'),
      icon: <Icon name="fa-regular fa-pencil" />,
      action: onEdit,
      visible: can('update directory'),
    },
  ];
};

const DirectoryActions: React.FC<{ dir: Dir }> = ({ dir }) => {
  const actions = useDirectoryActions(dir);

  return <ActionSheet actions={actions} />;
};

export default DirectoryActions;

export default Object.freeze({
  app: {
    providers: 'app_providers',
    extraRoutes: 'app_extra_routes',
    extraPublicRoutes: 'app_extra_public_routes',
    rootComponent: 'app_root_component',
    rootAssetsComponent: 'app_root_assets_component',
  },
  navbar: {
    content: {
      before: 'navbar_content_before',
      after: 'navbar_content_after',
    },
    items: 'navbar_items',
    extraItems: 'extra_items',
    usersTitle: 'navbar_items_users_title',
    myDiskTitle: 'navbar_items_my_disk_title',
    reportsTitle: 'navbar_items_reports_title',
    settingsTitle: 'navbar_items_my_settings_title',
    itemsOrder: 'navbar_items_order',
    actions: 'navbar_actions',
    defaultActions: 'navbar_default_actions',
    actionsVisibility: 'navbar_actions_visibility',
  },
  reports: {
    extraActions: 'reports_extra_actions',
    extraItemActions: 'reports_extra_item_actions',
    itemLinkedAttributes: 'reports_item_linked_attributes',
    typeAttributes: 'reports_item_type_attributes',
    serviceRequestDeviceAttributes: 'reports_service_request_device_attributes',
  },
  directories: {
    availableTypes: 'available_directory_types',
    contextualViewModelEnabled: 'contextual_view_mode_enabled',
    defaultSortableField: 'directories_default_sortable_field',
    defaultSortableDirection: 'directories_default_sortable_direction',
  },
  users: {
    form: {
      extraFields: 'users.form.extra_fields',
      extraFieldsModern: 'users.form.extra_fields_modern',
      availableRoles: 'users.form.available_roles',
    },
    labels: {
      users: 'Users',
      groups: 'Groups',
    },
    extraCrudViews: 'users_extra_crud_views',
  },
  components: {
    crudViews: 'components_crud_views',
  },
  settings: {
    extraCrudViews: 'settings_extra_crud_views',
  },
  feedback: {
    detailsComponent: 'feedback_details_component',
  },
  serviceTickets: {
    extraFilters: 'service_tickets.extra_filters',
  },
  intelligenceLayer: {
    initialValues: 'intelligence_layer.initial_values',
    fieldComponent: 'intelligence_layer.field.component',
  },
  topBar: {
    links: {
      files: 'top_bar_links_files',
    },
  },
  deviceGroups: {
    table: {
      columns: 'device_groups_table_columns',
    },
  },
  deviceTypes: {
    devices: {
      defaultFilters: 'device_types_devices_default_filters',
    },
  },
});

import React from 'react';
import http from 'utils/http';
import Spacer from '../../Spacer/Spacer';
import AutocompleteAdapter from './AutocompleteAdapter';
import { AdapterProps } from '../types';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';

export type ServerAutocompleteAdapterProps =
  AdapterProps['server-autocomplete'] &
    Omit<AdapterProps['autocomplete'], 'options'>;

type ServerResource = {
  id: number;
  name: string;
};

const ServerAutocompleteAdapter = ({
  optionsUrl,
  ...props
}: ServerAutocompleteAdapterProps) => {
  const { data = [], isLoading } = useQuery({
    queryKey: [optionsUrl, 'server-autocomplete-adapter-options'],
    queryFn: async () => {
      let items = await http
        .get<any, ServerResource[]>(optionsUrl)
        .then((response) => {
          // @ts-ignore
          if (response.data) {
            // @ts-ignore
            return response.data;
          }

          return response as ServerResource[];
        });
      // @ts-ignore
      return items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  if (isLoading) {
    return (
      <Stack direction="column" flex="1">
        <Skeleton width="100%" height={16} variant="rectangular" />
        <Spacer size={1} />
        <Skeleton width="100%" height={54} variant="rectangular" />
      </Stack>
    );
  }

  return <AutocompleteAdapter {...props} options={data} />;
};

export default ServerAutocompleteAdapter;

import React, { HTMLProps, MutableRefObject } from 'react';
import { FieldErrors, DefaultValues, UseFormReturn } from 'react-hook-form';

export const enum FieldType {
  Text = 'text',
  LongText = 'longtext',
  Number = 'number',
  Email = 'email',
  Password = 'password',
  Select = 'select',
  SelectCreatable = 'select-creatable',
  Autocomplete = 'autocomplete',
  ServerAutocomplete = 'server-autocomplete',
  Date = 'date',
  File = 'file',
  Checkbox = 'checkbox',
  Boolean = 'boolean',
}

export type FormRegistry = {
  [FieldType.Text]: React.ComponentType<FieldArgs<TextField>>;
  [FieldType.LongText]: React.ComponentType<FieldArgs<TextField>>;
  [FieldType.Number]: React.ComponentType<FieldArgs<TextField>>;
  [FieldType.Email]: React.ComponentType<FieldArgs<EmailField>>;
  [FieldType.Password]: React.ComponentType<FieldArgs<PasswordField>>;
  [FieldType.Select]: React.ComponentType<FieldArgs<SelectField>>;
  [FieldType.Date]: React.ComponentType<FieldArgs<DateField>>;
  [FieldType.SelectCreatable]: React.ComponentType<FieldArgs<SelectField>>;
  [FieldType.Autocomplete]: React.ComponentType<FieldArgs<SelectField>>;
  [FieldType.File]: React.ComponentType<FieldArgs<FileField>>;
  [FieldType.ServerAutocomplete]: React.ComponentType<
    FieldArgs<ServerAutocompleteField>
  >;
  [FieldType.Checkbox]: React.ComponentType<FieldArgs<CheckboxField>>;
  [FieldType.Boolean]: React.ComponentType<FieldArgs<CheckboxField>>;
};

export type AdapterProps = {
  [Key in FieldType]: React.ComponentProps<FormRegistry[Key]>;
};

export type Field = {
  name: string;
  label?: React.ReactNode;
  type: FieldType;
  rules?: any;
  disabled?: boolean;
  autoFocus?: boolean;
};

export type TextField = Field & {
  type: FieldType.Text;
  multiline?: boolean;
  minRows?: number;
};

export type EmailField = Field & {
  type: FieldType.Email;
};

export type PasswordField = Field & {
  type: FieldType.Password;
  autoComplete?: HTMLProps<HTMLInputElement>['autoComplete'];
};

export type DateField = Field & {
  type: FieldType.Date;
};

export type FileField = Field & {
  type: FieldType.File;
  multiple?: boolean;
  uploadLabel?: string;
  accept?: string;
};

type Option = {
  value: string | number;
  label: string;
};

export type SelectField = Field & {
  type: FieldType.Select;
  options: Option[];
};

export type ServerAutocompleteField = Field & {
  type: FieldType.ServerAutocomplete;
  optionsUrl: string;
  multiple?: boolean;
};

export type SelectCreatableField = Field & {
  type: FieldType.SelectCreatable;
  options: Option[];
};

export type AutocompleteField = Field & {
  type: FieldType.Autocomplete;
  options: Option[];
  multiple?: boolean;
};

export type CheckboxField = Field & {
  type: FieldType.Checkbox;
  defaultChecked?: boolean;
};

export type FormField =
  | TextField
  | EmailField
  | PasswordField
  | SelectField
  | SelectCreatableField
  | AutocompleteField
  | ServerAutocompleteField
  | FileField
  | DateField
  | CheckboxField;

export type FieldArgs<Field extends FormField> = Omit<Field, 'type'>;

export type FormFields<Values extends object> = {
  [K in keyof Values]: FormField;
};

type AsyncDefaultValues<TFieldValues> = (
  payload?: unknown,
) => Promise<TFieldValues>;

export type FormProps<Values extends object> = {
  onSubmit(values: Values): void | Promise<unknown>;
  onChange?(values: Values): void;
  onBeforeSubmit?(): void;
  onCancel?(): void;
  defaultValues?: DefaultValues<Values> | AsyncDefaultValues<Values>;
  errors?: FieldErrors<Values>;
  fields?: Array<FormField & { name: keyof Values | string }>;
  slots?: {
    save?: string;
    cancel?: string;
  };
  renderButtons?: boolean;
  buttonAlign?: 'left' | 'center' | 'right';
  formRef?: any;
  extraActions?: React.ReactNode;
};

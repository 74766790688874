import React from 'react';
import get from 'lodash/get';
import { useTableContext } from '../../../components/v2/Table/TableContextProvider';
import { applyFilters, Filters } from '../../../core';
import { useNavigate } from 'utils/router';
import { useReportContext } from '../../reports/context/ReportContext';
import HightLightQuery from 'modules/search/components/HighlightQuery';

export const AttributeValue = ({
  item,
  attribute,
  onDeviceTypeClick,
  onTitleClick,
}: any) => {
  const { id: reportId } = useReportContext();
  const navigate = useNavigate();
  const { itemDetailsAction } = useTableContext();

  const onItemDetailsClick = () => {
    if (itemDetailsAction) {
      return itemDetailsAction(item);
    }

    navigate(`/assets/report/${reportId}/view/${item.id}`);
  };

  const onTypeClick = (typeId: number, label: string) => {
    onDeviceTypeClick?.(typeId, label);
  };

  const paths = [`intelligenceFields.${attribute}`, attribute];

  for (let path of paths) {
    let value = get(item, path);

    const typeAttributes = applyFilters(Filters.reports.typeAttributes, [
      'type',
      'device_type',
    ]);

    if (typeAttributes.includes(attribute)) {
      const label =
        value && typeof value === 'object' && 'label' in value
          ? value.label
          : value;
      const deviceType =
        value && typeof value === 'object' && 'value' in value
          ? value.value
          : value;
      const deviceTypeValue = applyFilters(
        `report_results_attribute_value_${attribute}`,
        deviceType,
        {
          directory: item,
        },
      );

      return (
        <a
          className="link"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onTypeClick(deviceTypeValue, label);
          }}
        >
          <HightLightQuery value={label} />
        </a>
      );
    }

    const likedAttributes = applyFilters(Filters.reports.itemLinkedAttributes, [
      'serial_number',
    ]);

    if (likedAttributes.includes(attribute)) {
      return (
        <a className="link" onClick={onItemDetailsClick}>
          <HightLightQuery value={value} />
        </a>
      );
    }

    if (value && 'title' === attribute) {
      return (
        <a
          className="link"
          onClick={onTitleClick ? () => onTitleClick(item) : undefined}
        >
          <HightLightQuery value={value} />
        </a>
      );
    }

    if (value === 0 && String(attribute).includes('count')) {
      return '0';
    }

    if (value) {
      // Here we can't highlight because value may be a React.Node
      value = applyFilters(`report_results_attribute_${attribute}`, value, {
        item,
      });

      if (typeof value === 'object' && 'label' in value) {
        return <HightLightQuery value={value.label} />;
      }

      return <HightLightQuery value={value} />;
    }
  }
};
